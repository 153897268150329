import { isEmpty } from 'lodash';

export const USER_LEVEL = {
  SUPER_ADMIN: 1,
  ADMIN: 2,
  USER: 3,
};

export const USER_LEVEL_ARRAY = [
  { nombre: 'Super Admin', id: 1 },
  { nombre: 'Admin', id: 2 },
  { nombre: 'Usuario', id: 3 },
];

export const API_STATUS = {
  OK: 200,
  BAD_REQUEST: 404,
  UNAUTHORIZED: 401,
  INERNAL_SERVER_ERROR: 500,
};

export const SALE_STATUS = {
  CONFIRMED: 1,
  CANCELLED: 2,
  NOT_CONFIRMED: 3,
};

export const replaceDiacriticsToEn = (string) => {
  return isEmpty(string)
    ? ''
    : string
        .normalize('NFKD')
        .replace(/[^\w\s-_#:%!@$*+=(){}<>?|°~`'",.;^/&]/g, '')
        .replace(/\s/g, '');
};

export const MESES = {
  enero: 1,
  febrero: 2,
  marzo: 3,
  abril: 4,
  mayo: 5,
  junio: 6,
  julio: 7,
  agosto: 8,
  septiembre: 9,
  octubre: 10,
  noviembre: 11,
  diciembre: 12,
};

export const MovementTypesIn = [
  { id: 7, name: 'Entrada (Compra)' },
  { id: 1, name: 'Entrada (Ajuste)' },
];

export const MovementTypesOut = [{ id: 2, name: 'Salida (Ajuste)' }];

export const MOVEMENT_TYPE_PAGE = {
  in: 'in',
  out: 'out',
  transfer: 'transfer',
};

export const MovementTypes = [
  { id: 1, name: 'Entrada (Ajuste)' },
  { id: 2, name: 'Salida (Ajuste)' },
  { id: 3, name: 'Entrada (Cancelación Venta)' },
  { id: 4, name: 'Salida (Venta)' },
  { id: 5, name: 'Entrada (Nota De Credito)' },
  { id: 6, name: 'Salida (Cancelacion Nota De Credito)' },
  { id: 7, name: 'Entrada (Compra)' },
  { id: 8, name: 'Traspaso (Entrada)' },
  { id: 9, name: 'Traspaso (Salida)' },
];
