import ClubDashboardImg from '../../../assets/images/ClubDashboardImg.svg';
import ConsumptionMenuImg from '../../../assets/images/ConsumptionMenuImg.svg';
import ReportsImg from '../../../assets/images/ReportsImg.svg';
import SettingsImg from '../../../assets/images/SettingsImg.svg';
import ReceiptHistoryImg from '../../../assets/images/receiptHistory.svg';
import RightArrowImg from '../../../assets/images/settingsicon.svg';
import PromotionsMenuImg from '../../../assets/images/PromotionsMenuImg.svg';

const Navigation = [
  {
    id: 'main-section',
    title: 'GlobalNav_POS',
    list: [
      {
        key: 1,
        title: 'GlobalNav_Dashboard',
        id: 'Dashboard',
        icon: ClubDashboardImg,
        dimensions: { height: '20px', width: '20px' },
        redirectUrl: 'dashboard',
        isActive: true,
      },
      {
        key: 2,
        title: 'GlobalNav_NewSale',
        id: 'NewSale',
        icon: ConsumptionMenuImg,
        dimensions: { height: '20px', width: '20px' },
        redirectUrl: 'sale/newSale',
        isActive: true,
      },
      {
        key: 3,
        title: 'GlobalNav_Quotations',
        id: 'Quotations',
        icon: ConsumptionMenuImg,
        dimensions: { height: '20px', width: '20px' },
        redirectUrl: 'sale/quotations',
        isActive: true,
      },
      {
        key: 4,
        title: 'GlobalNav_Products',
        id: 'PromotionsMenu',
        icon: PromotionsMenuImg,
        dimensions: { height: '20px', width: '20px' },
        redirectUrl: 'product/landing',
        isActive: true,
      },
      {
        key: 5,
        title: 'GlobalNav_Inventory',
        id: 'PromotionsMenu',
        icon: PromotionsMenuImg,
        dimensions: { height: '20px', width: '20px' },
        isActive: true,
        secondaryIcon: RightArrowImg,
        submenu: [
          {
            id: 'settings-section',
            title: 'GlobalNav_Inventory',
            secondary: true,
            list: [
              {
                key: 6,
                title: 'GlobalNav_InventoryOutput',
                id: 'MyProfile',
                redirectUrl: 'inventory/movement/out',
                isActive: true,
              },
              {
                key: 7,
                title: 'GlobalNav_InventoryInput',
                id: 'DigitalDisplay',
                redirectUrl: 'inventory/movement/in',
                isActive: true,
              },
              {
                key: 8,
                title: 'GlobalNav_InventoryTransfer',
                id: 'DigitalDisplay',
                redirectUrl: 'inventory/movement/transfer',
                isActive: true,
              },
            ],
          },
        ],
      },
      {
        key: 9,
        title: 'GlobalNav_Accounts',
        id: 'NewReports',
        icon: ReportsImg,
        dimensions: { height: '20px', width: '20px' },
        redirectUrl: 'accounting/landing',
      },
      {
        key: 50,
        title: 'GlobalNav_Reports',
        id: 'ReceiptHistory',
        icon: ReceiptHistoryImg,
        secondaryIcon: RightArrowImg,
        dimensions: { height: '20px', width: '20px' },
        isActive: true,
        submenu: [
          {
            id: 'settings-section',
            title: 'GlobalNav_Reports',
            secondary: true,
            list: [
              {
                key: 51,
                title: 'GlobalNav_SaleHistory',
                id: 'MyProfile',
                redirectUrl: 'report/sales',
                isActive: true,
              },
              {
                key: 52,
                title: 'GlobalNav_InventoryReport',
                id: 'DigitalDisplay',
                redirectUrl: 'report/inventory',
                isActive: true,
              },
              {
                key: 53,
                title: 'GlobalNav_InventorySalesReportV1',
                id: 'DigitalDisplay',
                redirectUrl: 'report/inventoryInfoV1',
                isActive: true,
              },
              {
                key: 54,
                title: 'GlobalNav_InventorySalesReportV2',
                id: 'DigitalDisplay',
                redirectUrl: 'report/inventoryInfoV2',
                isActive: true,
              },
              {
                key: 55,
                title: 'GlobalNav_NewProductPricing',
                id: 'DigitalDisplay',
                redirectUrl: 'report/newProductPricing',
                isActive: true,
              },
              {
                key: 56,
                title: 'GlobalNav_SaleConsignment',
                id: 'DigitalDisplay',
                redirectUrl: 'report/saleConsignment',
                isActive: true,
              },
              {
                key: 57,
                title: 'GlobalNav_SalesReport',
                id: 'DigitalDisplay',
                redirectUrl: 'report/salesReport',
                isActive: true,
              },
              {
                key: 58,
                title: 'GlobalNav_BalanceResult',
                id: 'DigitalDisplay',
                redirectUrl: 'report/balanceResult',
                isActive: true,
              },
            ],
          },
        ],
      },
      {
        key: 100,
        title: 'GlobalNav_ML',
        id: 'MercadoLibre',
        icon: ReceiptHistoryImg,
        secondaryIcon: RightArrowImg,
        dimensions: { height: '20px', width: '20px' },
        isActive: true,
        submenu: [
          {
            id: 'settings-section',
            title: 'GlobalNav_ML',
            secondary: true,
            list: [
              {
                key: 103,
                title: 'GlobalNav_SyncSalesML',
                id: 'SyncSalesML',
                redirectUrl: 'ml/syncSales',
                isActive: true,
              },
              {
                key: 104,
                title: 'GlobalNav_SyncProductsML',
                id: 'SyncProductsML',
                redirectUrl: 'ml/syncProducts',
                isActive: true,
              },
              {
                key: 108,
                title: 'GlobalNav_GetMlStockFull',
                id: 'GetMlStockFull',
                redirectUrl: 'ml/getMlStockFull',
                isActive: true,
              },
              {
                key: 109,
                title: 'GlobalNav_UploadMlStockWithPenalties',
                id: 'UploadMlStockWithPenalties',
                redirectUrl: 'ml/uploadMlStockWithPenalties',
                isActive: true,
              },
              {
                key: 110,
                title: 'GlobalNav_GetMlStockWithPenalties',
                id: 'GetMlStockWithPenalties',
                redirectUrl: 'ml/getMlStockWithPenalties',
                isActive: true,
              },
              {
                key: 111,
                title: 'GlobalNav_GetMlStockFullRequest',
                id: 'GetMlStockFullRequest',
                redirectUrl: 'ml/GetMlStockFullRequest',
                isActive: true,
              },
              {
                key: 102,
                title: 'GlobalNav_ExcelSalesML',
                id: 'ExcelSalesML',
                redirectUrl: 'ml/excelSales',
                isActive: true,
              },
              {
                key: 105,
                title: 'GlobalNav_UploadMlProducts',
                id: 'UploadMlProducts',
                redirectUrl: 'ml/uploadMlProducts',
                isActive: true,
              },
              {
                key: 106,
                title: 'GlobalNav_UploadMlFullProducts',
                id: 'UploadMlFullProducts',
                redirectUrl: 'ml/uploadMlFullProducts',
                isActive: true,
              },
              {
                key: 107,
                title: 'GlobalNav_UploadMlStockFull',
                id: 'UploadMlStockFull',
                redirectUrl: 'ml/uploadMlStockFull',
                isActive: true,
              },
            ],
          },
        ],
      },
      {
        key: 400,
        title: 'GlobalNav_TN',
        id: 'TiendaNube',
        icon: ReceiptHistoryImg,
        secondaryIcon: RightArrowImg,
        dimensions: { height: '20px', width: '20px' },
        isActive: true,
        submenu: [
          {
            id: 'settings-section',
            title: 'GlobalNav_TN',
            secondary: true,
            list: [
              {
                key: 401,
                title: 'GlobalNav_ProductPrices',
                id: 'ProductPrices',
                redirectUrl: 'tn/uploadProductsPrices',
                isActive: true,
              },
            ],
          },
        ],
      },
      {
        key: 400,
        title: 'GlobalNav_ExcelProduct',
        id: 'TiendaNube',
        icon: ReceiptHistoryImg,
        secondaryIcon: RightArrowImg,
        dimensions: { height: '20px', width: '20px' },
        isActive: true,
        submenu: [
          {
            id: 'settings-section',
            title: 'GlobalNav_ExcelProduct',
            secondary: true,
            list: [
              {
                key: 401,
                title: 'GlobalNav_ExcelUploadProducts',
                id: 'ProductPrices',
                redirectUrl: 'excel/uploadProducts',
                isActive: true,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    id: 'settings-section',
    title: 'GlobalNav_Settings',
    list: [
      {
        key: 200,
        title: 'GlobalNav_Products',
        id: 'Settings',
        icon: SettingsImg,
        secondaryIcon: RightArrowImg,
        dimensions: { height: '20px', width: '20px' },
        isActive: true,
        submenu: [
          {
            id: 'settings-section',
            title: 'GlobalNav_Products',
            secondary: true,
            list: [
              {
                key: 201,
                title: 'GlobalNav_Suppliers',
                id: 'MyProfile',
                redirectUrl: 'configuration/supplier',
                isActive: true,
              },
              {
                key: 202,
                title: 'GlobalNav_Brands',
                id: 'DigitalDisplay',
                redirectUrl: 'configuration/brand',
                isActive: true,
              },
              {
                key: 203,
                title: 'GlobalNav_Families',
                id: 'ClubProfile',
                redirectUrl: 'configuration/family',
                isActive: true,
              },
              {
                key: 204,
                title: 'GlobalNav_Subfamilies',
                id: 'ManageOperators',
                redirectUrl: 'configuration/subfamily',
                isActive: true,
              },
              {
                key: 205,
                title: 'GlobalNav_PriceType',
                id: 'ManageOperators',
                redirectUrl: 'configuration/priceType',
                isActive: true,
              },
            ],
          },
        ],
      },
      {
        key: 300,
        title: 'GlobalNav_Customers',
        id: 'NewReports',
        icon: ReportsImg,
        dimensions: { height: '20px', width: '20px' },
        redirectUrl: 'configuration/customer',
      },
      {
        key: 301,
        title: 'GlobalNav_Branchs',
        id: 'NewReports',
        icon: ReportsImg,
        dimensions: { height: '20px', width: '20px' },
        redirectUrl: 'configuration/branch',
      },
      {
        key: 302,
        title: 'GlobalNav_Users',
        id: 'NewReports',
        icon: ReportsImg,
        dimensions: { height: '20px', width: '20px' },
        redirectUrl: 'configuration/user',
      },
      {
        key: 303,
        title: 'GlobalNav_Platform',
        id: 'NewReports',
        icon: ReportsImg,
        dimensions: { height: '20px', width: '20px' },
        redirectUrl: 'configuration/platform',
      },
    ],
  },
];

export default Navigation;
