import React, { Fragment, useEffect, useState } from 'react';
import _ from 'lodash';
import { CheckUserStorage, SetUserStorage } from './api/LocalStorageManager';
import {
  GetAccounts,
  GetAllProductsWithPrices,
  GetBranchs,
  GetBrands,
  GetConfiguration,
  GetCustomers,
  GetFamilies,
  GetMovementsAccount,
  GetMovementsAccountByDate,
  GetPriceTypeByCustomer,
  GetPriceTypes,
  GetProductConsignees,
  GetSingleProductById,
  GetSubfamilies,
  GetSuppliers,
} from './api/PosApiCalls';
import Loading from './common/loading/Loading';
import { appContext } from './contexts/context';
import appBootstrap from './initialization/app-bootstrap';
import countryConfigFactory from './initialization/countryConfig/countryConfigFactory';
import envConfig from './initialization/environment-config';
import Strings from './localization/Localization';
import GlobalNav from './navigation/GlobalNav';
import { API_STATUS } from './util/Constants';
import { convertDateToStringWithFormat } from './common/dateTime/MomentDateTime';
import { useLocation, useNavigate, Outlet, useMatches } from 'react-router-dom';
import queryString from 'query-string';

export const Layout = () => {
  const [title, setTitle] = useState('');
  const [searchTextProduct, setSearchTextProduct] = useState('');
  const [configuration, setConfiguration] = useState({});
  const [appInitDone, setAppInitDone] = useState(false);
  const [dataInitDone, setDataInitDone] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const params = useMatches();

  useEffect(() => {
    setTitle('Grupo Nario');
    envConfig.setLocale().then(async (locale) => {
      //init localization strings with determined locale
      setApplicationLanguage(locale).then(() => {
        setCountryConfigApplication(locale).then(() => {
          //initialize auth interceptors
          appBootstrap.initializeAuth().then(async () => {
            //get uer info and init
            getConfigurations().then((memberProfile) => {
              //initialize the app with the current user
              //user is considered authenticated if we got their profile back
              return appBootstrap.initializeEnv(memberProfile).then(() => {
                setAppInitDone(true);
                setDataInitDone(true);
                checkLogin();
                return Promise.resolve();
              });
            });
            document.title = 'POS';
          });
        });
      });
    });
  }, []);

  const checkLogin = () => {
    if (CheckUserStorage() === null) {
      onGoTo('login');
    }
  };

  const setLogin = (user) => {
    SetUserStorage(user);
    onGoTo('dashboard');
  };

  const getConfigurations = async () => {
    let response = await GetConfiguration();
    if (response.status === API_STATUS.OK) {
      let configuration = response.data;
      configuration.centralizedCashRegister =
        configuration.centralizedCashRegister === 1;
      configuration.branchPriceCentralization =
        configuration.branchPriceCentralization === 1;
      configuration.directConfirmation = configuration.directConfirmation === 1;
      configuration.taxBreakdown = configuration.taxBreakdown === 1;
      configuration.priceEdition = configuration.priceEdition === 1;
      configuration.printOnlyTicket = configuration.printOnlyTicket === 1;
      configuration.netPrices = configuration.netPrices === 1;
      configuration.saleWithInventory = configuration.saleWithInventory === 1;
      configuration.enableAmazon = configuration.enableAmazon === 1;
      configuration.enableMl = configuration.enableMl === 1;
      configuration.enableOlist = configuration.enableOlist === 1;
      configuration.enableTn = configuration.enableTn === 1;
      setConfiguration(configuration);
    }
    return Promise.resolve({ ...response.data, app: 'POS' });
  };

  const setApplicationLanguage = (locale) => {
    let localizedPromise = new Promise((resolve) => {
      let localeString = locale.replace(/-/g, '').replace(/_/g, '');
      import('./localization/locales/' + localeString + '.localization').then(
        (localization) => {
          if (localization && localization.default) {
            Strings.setContent(
              Object.assign({}, Strings.getContent(), {
                [locale]: localization.default,
              })
            );
            Strings.setLanguage(locale);
            resolve();
          }
        }
      );
    });
    return localizedPromise;
  };

  const setCountryConfigApplication = async (locale) => {
    const countryConfig = await countryConfigFactory.createConfig(locale);
    envConfig.countryConfig = countryConfig ? countryConfig : {};
    envConfig.currencySymbol =
      countryConfig &&
      countryConfig.currencyInfo &&
      countryConfig.currencyInfo.symbol;
  };

  const onGoTo = (target) => {
    console.log(location);
    let url = location.pathname.replace(/\//g, '') || '';
    let targetUrl = target.replace(/\//g, '') || '';
    if (url.toLowerCase() !== targetUrl.toLowerCase()) {
      navigate('/' + target);
    }
  };

  const getPageParams = () => {
    console.log(params);
    return params;
  };

  const onGoBack = () => {
    navigate(-1);
  };

  const checkSearchParams = (param) => {
    let queryResult = queryString.parse(location.search);
    if (queryResult && queryResult[param]) {
      return queryResult[param];
    }
  };

  const saveProductSearch = (search) => {
    setSearchTextProduct(search);
  };

  const getPriceTypeForCustomer = async (customerId) => {
    let response = await GetPriceTypeByCustomer(customerId);
    if (response.status === API_STATUS.OK) {
      return response.data;
    } else {
      return {};
    }
  };

  const getAccounts = async () => {
    let response = await GetAccounts();
    if (response.status === API_STATUS.OK) {
      return response.data;
    } else {
      return {};
    }
  };

  const getMovementsAccount = async () => {
    let response = await GetMovementsAccount();
    if (response.status === API_STATUS.OK) {
      return response.data;
    } else {
      return {};
    }
  };

  const getMovementsAccountByDate = async (startDate, endDate) => {
    let response = await GetMovementsAccountByDate(
      convertDateToStringWithFormat(
        startDate,
        Strings.reports_api_format_start
      ),
      convertDateToStringWithFormat(endDate, Strings.reports_api_format_end)
    );
    if (response.status === API_STATUS.OK) {
      response.data.map((data) => {
        data.amount = data.movementType === 2 ? data.amount * -1 : data.amount;
        return data;
      });
      return response.data;
    } else {
      return {};
    }
  };

  const getAllProductsWithPrices = async (
    branchId,
    onlyActiveSkus = true,
    force = false
  ) => {
    let response = await GetAllProductsWithPrices(
      branchId,
      onlyActiveSkus,
      force
    );
    if (response.status === API_STATUS.OK) {
      return response.data;
    } else {
      return [];
    }
  };

  const getProductInfo = async (id) => {
    let response = await GetSingleProductById(id);
    if (response.status === API_STATUS.OK) {
      return response.data;
    } else {
      return {};
    }
  };

  const getBranchs = async () => {
    let response = await GetBranchs();
    if (response.status === API_STATUS.OK) {
      let branchs = _.orderBy(response.data, 'id', 'asc');
      return branchs;
    } else {
      return [];
    }
  };

  const getBrands = async () => {
    let response = await GetBrands();
    if (response.status === API_STATUS.OK) {
      let brands = _.orderBy(response.data, 'id', 'asc');
      return brands;
    } else {
      return [];
    }
  };

  const getProductConsignees = async () => {
    let response = await GetProductConsignees();
    if (response.status === API_STATUS.OK) {
      let productConsignees = _.orderBy(response.data, 'id', 'asc');
      return productConsignees;
    } else {
      return [];
    }
  };

  const getPriceTypes = async () => {
    let response = await GetPriceTypes();
    if (response.status === API_STATUS.OK) {
      let priceTypes = _.orderBy(response.data, 'id', 'asc');
      return priceTypes;
    } else {
      return [];
    }
  };

  const getSuppliers = async () => {
    let response = await GetSuppliers();
    if (response.status === API_STATUS.OK) {
      let suppliers = _.orderBy(response.data, 'id', 'asc');
      return suppliers;
    } else {
      return [];
    }
  };

  const getFamilies = async () => {
    let response = await GetFamilies();
    if (response.status === API_STATUS.OK) {
      let families = _.orderBy(response.data, 'id', 'asc');
      return families;
    } else {
      return [];
    }
  };

  const getSubfamilies = async () => {
    let response = await GetSubfamilies();
    if (response.status === API_STATUS.OK) {
      let subfamilies = _.orderBy(response.data, 'id', 'asc');
      return subfamilies;
    } else {
      return [];
    }
  };

  const getCustomers = async () => {
    let response = await GetCustomers();
    if (response.status === API_STATUS.OK) {
      response.data.map((customer) => {
        customer.status = customer.isActive ? 'Si' : 'No';
        return customer;
      });
      let customers = _.orderBy(response.data, 'id', 'asc');
      return customers;
    } else {
      return [];
    }
  };

  const getActiveCustomers = async () => {
    let customers = await getCustomers();
    return customers.filter((customer) => customer.isActive);
  };

  return (
    <div
      id='app-container'
      className={_.includes(location.pathname, 'login') ? 'login' : ''}
    >
      <appContext.Provider
        value={{
          state: {
            title: title,
            configuration: configuration,
            appInitDone: appInitDone,
            dataInitDone: dataInitDone,
            searchTextProduct: searchTextProduct,
          },
          onGoTo: onGoTo,
          onGoBack: onGoBack,
          getPageParams: getPageParams,
          checkSearchParams: checkSearchParams,
          setLogin: setLogin,
          saveProductSearch: saveProductSearch,
          getConfigurations: getConfigurations,
          getPriceTypeForCustomer: getPriceTypeForCustomer,
          getBrands: getBrands,
          getProductConsignees: getProductConsignees,
          getProductInfo: getProductInfo,
          getBranchs: getBranchs,
          getPriceTypes: getPriceTypes,
          getSuppliers: getSuppliers,
          getFamilies: getFamilies,
          getSubfamilies: getSubfamilies,
          getCustomers: getCustomers,
          getActiveCustomers: getActiveCustomers,
          getAllProductsWithPrices: getAllProductsWithPrices,
          getAccounts: getAccounts,
          getMovementsAccount: getMovementsAccount,
          getMovementsAccountByDate: getMovementsAccountByDate,
        }}
      >
        {!appInitDone && <Loading />}
        <Fragment>
          {!_.includes(location.pathname, 'login') && <GlobalNav />}
          <div className='page-body'>
            <Outlet />
          </div>
        </Fragment>
      </appContext.Provider>
    </div>
  );
};

export default Layout;
