import { includes } from 'lodash';

const host = 'https://api.gruponario.com/';
const hostLocal = 'https://api.gruponario.com/';
const hostBackup = 'http://nario92-002-site2.dtempurl.com/';
const hostLocalServer = 'http://localhost:1002/';
const endpoint = {
  GetAppId: 'api/Ml/GetAppId',
  GetMlUrl: 'api/Ml/GetMlUrl',
  GenerateMlAccessToken: 'api/Ml/GenerateMlAccessToken',
  GetDashboard: 'api/Sale/GetDashboard',
  GetSale: 'api/Sale/GetSale',
  GetSalesV2: 'api/Sale/GetSalesV2',
  SaleDetailGetList: 'api/Sale/SaleDetailGetList',
  CheckSale: 'api/Sale/CheckSale',
  CheckSaleList: 'api/Sale/CheckSaleList',
  GetQuotations: 'api/Sale/GetQuotations',
  GetSaleDetail: 'api/Sale/GetSaleDetail',
  GetSaleConsignment: 'api/Sale/GetSaleConsignment',
  GetProductConsigneePayments: 'api/Sale/GetProductConsigneePayments',
  GetInventoryMovements: 'api/Inventory/GetInventoryMovements',
  GetInventoryReportV1: 'api/Inventory/GetInventoryReportV1',
  GetInventoryReportV2: 'api/Inventory/GetInventoryReportV2',
  GetAllProductsWithPrices: 'api/Product/GetAllProductsWithPrices',
  GetSingleProduct: 'api/Product/GetSingleProduct',
  GetAccounts: 'api/Accounting/GetAccounts',
  GetMovementsAccount: 'api/Accounting/GetMovementsAccount',
  GetMovementsAccountByDate: 'api/Accounting/GetMovementsAccountByDate',
  GetPaymentTypes: 'api/Product/GetPaymentTypes',
  GetMlStockFull: 'api/Ml/GetMlStockFull',
  GetMlStockWithPenalties: 'api/Ml/GetMlStockWithPenalties',
  GetMlStockFullRequest: 'api/Ml/GetMlStockFullRequest',
  GetMlSales: 'api/Ml/GetMlSales',
  GetMlProductsList: 'api/Ml/GetMlProductsList',
  GetMlProducts: 'api/Ml/GetMlProducts',
  GetMlProductVariation: 'api/Ml/GetMlProductVariation',
  GetSubfamilies: 'api/Configuration/GetSubfamilies',
  GetSubfamily: 'api/Configuration/GetSubfamily',
  GetFamilies: 'api/Configuration/GetFamilies',
  GetFamily: 'api/Configuration/GetFamily',
  GetSuppliers: 'api/Configuration/GetSuppliers',
  GetSupplier: 'api/Configuration/GetSupplier',
  GetBrands: 'api/Configuration/GetBrands',
  GetBrand: 'api/Configuration/GetBrand',
  GetProductConsignees: 'api/Configuration/GetProductConsignees',
  GetProductConsignee: 'api/Configuration/GetProductConsignee',
  GetBranchs: 'api/Configuration/GetBranchs',
  GetBranch: 'api/Configuration/GetBranch',
  GetUsers: 'api/Configuration/GetUsers',
  GetUser: 'api/Configuration/GetUser',
  GetPriceTypes: 'api/Configuration/GetPriceTypes',
  GetPriceType: 'api/Configuration/GetPriceType',
  GetCustomers: 'api/Configuration/GetCustomers',
  GetCustomer: 'api/Configuration/GetCustomer',
  GetPriceTypeByCustomer: 'api/Configuration/GetPriceTypeByCustomer',
  GetConfiguration: 'api/Configuration/GetConfiguration',
  UpdateConfiguration: 'api/Configuration/UpdateConfiguration',
  CheckUser: 'api/Login/CheckUser',
  CancelSale: 'api/Sale/CancelSale',
  SetUser: 'api/Configuration/SetUser',
  SetCustomer: 'api/Configuration/SetCustomer',
  SetPriceType: 'api/Configuration/SetPriceType',
  SetSubfamily: 'api/Configuration/SetSubfamily',
  SetFamily: 'api/Configuration/SetFamily',
  SetSupplier: 'api/Configuration/SetSupplier',
  SetBrand: 'api/Configuration/SetBrand',
  SetBranch: 'api/Configuration/SetBranch',
  SetSale: 'api/Sale/SetSale',
  SetConfirmSale: 'api/Sale/SetConfirmSale',
  SetQuotation: 'api/Sale/SetQuotation',
  SetConsigneePayment: 'api/Sale/SetConsigneePayment',
  DeleteQuotation: 'api/Sale/DeleteQuotation',
  SetProduct: 'api/Product/SetProduct',
  SetProductByExcel: 'api/Product/SetProductByExcel',
  SetMovementAccount: 'api/Accounting/SetMovementAccount',
  SaveInventoryIn: 'api/Inventory/SaveInventoryIn',
  SaveInventoryOut: 'api/Inventory/SaveInventoryOut',
  SaveInventoryTransfer: 'api/Inventory/SaveInventoryTransfer',
  SaveMlProductInfo: 'api/Ml/SaveMlProductInfo',
  SaveMlFullProductInfo: 'api/Ml/SaveMlFullProductInfo',
  SaveMlStockFull: 'api/Ml/SaveMlStockFull',
  SaveMlStockWithPenalties: 'api/Ml/SaveMlStockWithPenalties',
  SaveTnProductInfo: 'api/Tn/SaveTnProductInfo',
  ProductSkuCheck: 'api/Product/ProductSkuCheck',
  ProductVisibilityUpdateSingle: 'api/Product/ProductVisibilityUpdateSingle',
};
const posEndpoints = (name) => {
  let endpointServer = host;
  if (includes(window.location.host, 'localhost:1001')) {
    endpointServer = hostLocalServer;
  } else if (includes(window.location.host, 'localhost')) {
    endpointServer = hostLocal;
  } else if (includes(window.location.host, 'itempurl')) {
    endpointServer = hostBackup;
  } else if (includes(window.location.host, 'ngrok')) {
    endpointServer = hostLocalServer;
  }
  let suffix = endpoint[name] || '';
  let url = endpointServer + suffix;
  return url;
};

export default posEndpoints;
