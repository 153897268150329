let esMX = {
  GlobalNav_POS: 'Punto De Venta',
  GlobalNav_Dashboard: 'Dashboard',
  GlobalNav_NewSale: 'Nueva Venta',
  GlobalNav_Quotations: 'Cotizaciones',
  GlobalNav_Sales: 'Venta',
  GlobalNav_ExcelSalesML: 'Excel De Ventas (Deprecated)',
  GlobalNav_SyncSalesML: 'Sincronizar Ventas ML',
  GlobalNav_SyncProductsML: 'Sincronizar Productos ML',
  GlobalNav_Products: 'Productos',
  GlobalNav_Inventory: 'Inventarios',
  GlobalNav_Accounts: 'Cuentas',
  GlobalNav_Reports: 'Reportes',
  GlobalNav_ML: 'Mercado Libre',
  GlobalNav_TN: 'Tienda Nube',
  GlobalNav_ExcelProduct: 'Actualizar por excel',
  GlobalNav_ProductPrices: 'Actualizar Precios',
  GlobalNav_ExcelUploadProducts: 'Actualizar Productos',
  GlobalNav_GetMlStockFull: 'Consultar Reporte Stock Full',
  GlobalNav_UploadMlProducts: 'Subir Detalle Productos (Deprecated)',
  GlobalNav_UploadMlFullProducts: 'Subir Detalle Productos Full (Deprecated)',
  GlobalNav_UploadMlStockFull: 'Subir Reporte Stock Full (Deprecated)',
  GlobalNav_GetMlStockWithPenalties: 'Consultar Reporte Stock Antiguo Full',
  GlobalNav_UploadMlStockWithPenalties: 'Subir Reporte Stock Antiguo Full',
  GlobalNav_GetMlStockFullRequest: 'Pedido Stock Full ML',
  GlobalNav_Settings: 'Configuraciones',
  GlobalNav_Customers: 'Clientes',
  GlobalNav_PriceType: 'Tipos de Precio',
  GlobalNav_Users: 'Usuarios',
  GlobalNav_Branchs: 'Sucursales',
  GlobalNav_Platform: 'Plataforma',
  GlobalNav_Suppliers: 'Proveedores',
  GlobalNav_Brands: 'Marcas',
  GlobalNav_Families: 'Familias',
  GlobalNav_Subfamilies: 'Subfamilias',
  GlobalNav_Permissions: 'Permisos',
  GlobalNav_DetailedProducts: 'Productos Detallados',
  GlobalNav_InventoryOutput: 'Salida',
  GlobalNav_InventoryInput: 'Entrada',
  GlobalNav_InventoryTransfer: 'Transferencia',
  GlobalNav_SaleHistory: 'Historial De Ventas',
  GlobalNav_InventorySalesReportV1: 'Informacion de Inventarios V1',
  GlobalNav_InventorySalesReportV2: 'Informacion de Inventarios V2',
  GlobalNav_InventoryReport: 'Historial de Movimientos de Inventarios',
  GlobalNav_NewProductPricing: 'Precio de Nuevo Producto',
  GlobalNav_SaleConsignment: 'Ventas a Consignacion',
  GlobalNav_SalesReport: 'Reporte de Ventas',
  GlobalNav_BalanceResult: 'Balance de Resultados',
  GlobalNav_Logut: 'Salir',
  GlobalNav_Locale: 'Local: {0}',
  GlobalNav_Version: 'Version: {0}',
  Items_ItemSelectionsTitle: 'Nombre del artículo',
  Items_ItemSelectionsDisplayingOf: 'Mostrando {0} de {1}',
  Items_ItemSelectionsPrice: 'Precio',
  Items_ItemSelectionsBtnExportMenu: 'Exportar productos',
  sorting_name_asc: 'Nombre del artículo (A-Z)',
  sorting_name_desc: 'Nombre del artículo (Z-A)',
  sorting_sku_asc: 'Codigo del artículo (A-Z)',
  sorting_sku_desc: 'Codigo del artículo (Z-A)',
  sorting_price_asc: 'Precio (menor a mayor)',
  sorting_price_desc: 'Precio (mayor a menor)',
  reports_date_format: 'D MMMM, YYYY',
  reports_api_format_start: 'YYYY-MM-DD 00:00:00',
  reports_api_format_end: 'YYYY-MM-DD 23:59:59',
};
export default esMX;
